import React from "react";

import Slider from "react-slick";

const Testimonials = ({ classicHeader, darkTheme }) => {
  const reviews = [
    {
      name: "Cody Davis",
      src: "images/testimonial/CodyDavis.jpg",
      desc: "Working with John was a transformative experience for our company, as his expertise in software development not only delivered a top-tier website but also introduced ongoing efficiencies, and his attention to detail and commitment ensured was a resounding success.",
      position: "Founder",
      rating: 5,
    },
    {
      name: "Kevin Letnes",
      position: "Co-Founder",
      src: "images/testimonial/KevinLetnes.jpg",
      desc: "Thanks to John's exemplary work, our platform's performance reached unprecedented levels; his unwavering dedication to addressing our challenges, combined with his prowess in efficiently tackling complex tasks, has established a new benchmark of excellence for us.",
      rating: 5,
    },
    {
      name: "Boris Foxman",
      position: "Founder",
      src: "images/testimonial/BorisFoxman.jpg",
      desc: "Hiring John was one of the best decisions we've made. His innovative approach to problem-solving ensured our project was not only delivered on time but exceeded all expectations.",
      rating: 5,
    },
    {
      name: "Sara Vaziri",
      position: "Founder",
      src: "images/testimonial/SaraVaziri.jpg",
      desc: "John's software solutions have been pivotal in providing us a competitive edge in the market; we value his exceptional skills and collaborative spirit, and his passion for software development is evident in every project he undertakes.",
      rating: 5,
    },
  ];

  var settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    focusOnSelect: true,
    arrows: false,
    centerPadding: "300px",
    slidesToShow: 1,
    speed: 1000,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",

          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme
                ? "text-muted-custom opacity-1"
                : "text-light opacity-4")
            }
          >
            Testimonial
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Love Valued Clients from Across the Globe
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid rounded-circle border d-inline-block"
                      style={{ width: "65px" }}
                      src={value.src}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted-custom fw-500">
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                  >
                    “{value.desc}”
                  </p>
                  <span className="text-2">
                    {[...Array(value.rating)].map((value, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                  </span>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
