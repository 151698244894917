import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {};

  const projectsData = [
    {
      title: "Faraday Future",
      projectInfo:
        "Faraday Future is a pioneering electric vehicle manufacturer dedicated to advancing global mobility solutions through innovation and technology. With a focus on smart, connected, and electric vehicles, FF aims to redefine the driving experience and provide sustainable transportation options. The website serves as a primary platform to showcase their vehicle models, technology innovations, and company news.\nI've managed both Front-end and Back-end tasks, utilizing React for the Front-end and Node.js for the Back-end.",
      industry: "Electric Vehicle Manufacturing",
      technologies:
        "React, Next.js, ExpressJS, Node.js, JavaScript, TypeScript, HTML5, CSS3, REST API, GSAP, Responsive Design",
      url: {
        name: "www.ff.com",
        link: "https://www.ff.com/",
      },
      thumbImage: "images/projects/React1/Initial.png",
      sliderImages: [
        "images/projects/React1/Screenshot_1.png",
        "images/projects/React1/Screenshot_2.png",
        "images/projects/React1/Screenshot_3.png",
      ],
      categories: [filters.BRAND],
    },
    {
      title: "Magazineluiza",
      projectInfo:
        "Magazineluiza stands as a premier shopping management platform leading the market. The platform utilizes MySQL 5.7 for its database, while the GUI is crafted using Twitter Bootstrap combined with jQuery. Additionally, certain pages leverage ReactJS. The test suite incorporates Minitest, complemented with transactional fixtures, as well as Capybara + Poltergeist for feature tests.\nI served in the capacity of a Full-stack Developer. My primary responsibilities spanned crafting new user interfaces using ReactJS and TypeScript, collaborating with a team of three to develop front-end pages, and troubleshooting bugs. My role also extended to database administration, executing some back-end tasks using NodeJS, and ensuring timely dependency upgrades.\nFurthermore, I managed the Continuous Integration (CI) process to initiate the environment, execute front-end and back-end code linting, administer unit and integration tests, and to deploy the production version of the application. To ensure thoroughness, I utilized Codecov to address any untested segments of the application, achieving an impressive code coverage of 94%.",
      industry: "E-commerce and Physical Stores",
      technologies:
        "React, Node.js, JavaScript, TypeScript, jQuery, HTML5, CSS3, Responsive Design, UI/UX Prototyping, Genesis Framework",
      url: {
        name: "www.magazineluiza.com.br",
        link: "https://www.magazineluiza.com.br/",
      },
      thumbImage: "images/projects/React2/Initial.png",
      sliderImages: [
        "images/projects/React2/Screenshot_2.png",
        "images/projects/React2/Screenshot_3.png",
        "images/projects/React2/Screenshot_4.png",
      ],
      categories: [filters.BRAND],
    },
    {
      title: "Sport Fishing Magazine",
      projectInfo:
        "Sport Fishing Magazine's website offers a comprehensive source of information for avid anglers and fishing enthusiasts. It covers a wide array of topics, including fishing techniques, gear reviews, boating tips, species identification, travel destinations, and recent news related to sport fishing.\nAs a Full-stack Developer on the Sport Fishing Magazine project, my chief responsibilities revolved around developing new user interfaces, working closely with a team of designers and developers to build front-end pages, and troubleshooting any existing issues. I also played a significant role in database management, handling back-end tasks, and ensuring that all dependencies remained up-to-date. My involvement in the project also extended to managing the CI/CD process, ensuring seamless updates and deployments.",
      industry: "Fishing, Outdoors, Media & Publishing",
      technologies:
        "React, Redux, Node.js, JavaScript, HTML5, CSS3, Adobe Analytics, Jest, Chai, Amazon Web Services",
      url: {
        name: "www.sportfishingmag.com",
        link: "https://www.sportfishingmag.com/",
      },
      thumbImage: "images/projects/React3/Initial.png",
      sliderImages: [
        "images/projects/React3/Screenshot_1.png",
        "images/projects/React3/Screenshot_2.png",
        "images/projects/React3/Screenshot_3.png",
      ],
      categories: [filters.BRAND],
    },
    {
      title: "Kaiser Permanente",
      projectInfo:
        "Kaiser Permanente's website provides a comprehensive platform for members, potential members, and other visitors to access healthcare services, information, and resources. It's a holistic platform offering medical advice, appointment scheduling, medication refills, and more.\nAs a Full-stack Developer on the Kaiser Permanente project, my main responsibilities revolved around enhancing the user experience, collaborating with a team to design and develop new features, and ensuring the platform's security. Additionally, I was instrumental in integrating various services, optimizing the database for performance, and adhering to the strict healthcare compliance and regulations, including HIPAA.",
      technologies:
        "Angular, JavaScript, ASP.NET, C, Azure DevOps, .NET Framework, Microsoft SQL Server, Microsoft Azure, RESTful API, Amazon Web Services, Microsoft SQL Server",
      url: {
        name: "healthy.kaiserpermanente.org",
        link: "https://healthy.kaiserpermanente.org/",
      },
      thumbImage: "images/projects/Angular/Initial.png",
      sliderImages: [
        "images/projects/Angular/Screenshot_1.png",
        "images/projects/Angular/Screenshot_2.png",
        "images/projects/Angular/Screenshot_3.png",
      ],
      categories: [filters.BRAND],
    },
    {
      title: "OG Clearinghouse",
      projectInfo:
        "OG Clearinghouse offers a platform dedicated to the acquisition and divestiture market for oil and gas assets. With a wide range of listed assets and a robust search functionality, it aims to connect sellers with potential buyers in the industry, ensuring a smooth transition and transaction process.\nAs a Full-stack Developer, I was actively involved in both the design and implementation of OG Clearinghouse. My responsibilities ranged from setting up the listing feature, incorporating the advanced search functionality, ensuring the site was mobile-responsive, to implementing backend logic for user account management. Collaborating closely with the UI/UX team, we ensured a user-friendly experience, making asset trading as seamless as possible for both sellers and buyers.",
      url: {
        name: "www.ogclearinghouse.com",
        link: "https://www.ogclearinghouse.com/",
      },
      thumbImage: "images/projects/C1/Initial.png",
      sliderImages: [
        "images/projects/C1/Screenshot_1.png",
        "images/projects/C1/Screenshot_2.png",
        "images/projects/C1/Screenshot_3.png",
      ],
      categories: [filters.BRAND],
    },
    {
      title: "Game Show Battle Rooms",
      projectInfo:
        "Game Show Battle Rooms offers a unique, interactive game show experience for groups looking for fun and competitive activities. With multiple locations, the platform serves as a hub for bookings, information, and showcasing what participants can expect during their visit.\nAs a Web Developer for Game Show Battle Rooms, I was involved in conceptualizing and implementing the online booking system. Collaborating with the design team, I ensured a responsive and user-friendly interface. I also integrated the site with payment gateways, ensuring a seamless checkout process for customers. Regularly updating the gallery with fresh content and ensuring the site's performance was optimized were also part of my responsibilities.",
      industry: "Entertainment & Events",
      technologies:
        "C, ASP.NET MVC, ASP.NET Core, React, HTML5, CSS3, Twitter Bootstrap, RESTful API, Microsoft IIS, Microsoft SQL Server, GraphQL",
      url: {
        name: "gameshowbattlerooms.com",
        link: "https://gameshowbattlerooms.com/",
      },
      thumbImage: "images/projects/C2/Initial.png",
      sliderImages: [
        "images/projects/C2/Screenshot_1.png",
        "images/projects/C2/Screenshot_2.png",
        "images/projects/C2/Screenshot_3.png",
      ],
      categories: [filters.BRAND],
    },
    {
      title: "Dragotec",
      projectInfo:
        "Dragotec is a leader in the design and manufacture of corn heads. Their website serves as a hub for showcasing their products, offering resources to farmers, and providing company-related news and updates.\nAs a Web Developer for Dragotec, my role encompassed developing and enhancing the Dealer Locator tool. This involved integrating an interactive map and ensuring it accurately plotted dealer locations. Collaborating with the design team, I helped improve the user interface, ensuring product pages were intuitive and provided comprehensive information. Regularly updating the Resources section, optimizing website speed, and ensuring a mobile-responsive design were also among my key responsibilities.",
      technologies:
        "C, ASP.NET, ASP.NET MVC, Angular, JavaScript, SaaS, Azure DevOps, Microservice, SQL, System Maintenance",
      url: {
        name: "www.dragotec.com",
        link: "https://www.dragotec.com/",
      },
      thumbImage: "images/projects/C3/Initial.png",
      sliderImages: [
        "images/projects/C3/Screenshot_1.png",
        "images/projects/C3/Screenshot_2.png",
        "images/projects/C3/Screenshot_3.png",
      ],
      categories: [filters.BRAND],
    },
    {
      title: "Six Flags",
      projectInfo:
        "Six Flags is a major chain of amusement parks and theme parks located in North America. Their website is a centralized platform that provides information on all the parks, rides, events, tickets, and more, offering visitors a comprehensive guide and the ability to plan and book their trips.\nAs a Web Developer for Six Flags, I was responsible for integrating the e-commerce functionality to streamline ticket purchases. Collaborating with the UX/UI team, I optimized the user journey from selecting a park to finalizing a ticket purchase. I also worked on enhancing the mobile responsiveness of the site, ensuring visitors had a seamless experience across devices. Lastly, I assisted in integrating the Membership Portal, providing members a centralized platform to manage their account, view exclusive offers, and more.",
      industry: "Entertainment & Amusement Parks",
      technologies:
        "Python, Django, React, React Bootstrap, API Integration, Docker Compose, GraphQL, Data Scraping, PostgreSQL, Amazon Web Services",
      url: {
        name: "www.sixflags.com",
        link: "https://www.sixflags.com/",
      },
      thumbImage: "images/projects/Python1/Initial.png",
      sliderImages: [
        "images/projects/Python1/Screenshot_1.png",
        "images/projects/Python1/Screenshot_2.png",
        "images/projects/Python1/Screenshot_3.png",
      ],
      categories: [filters.BRAND],
    },
    {
      title: "GiveGet",
      projectInfo:
        "Give + Get is all about tapping into that innate human desire to help others; “Give”, while at the same time joining a community of active people who complete amazing challenges and reap the rewards they offer; “Get”. Bringing charitable giving into the modern era with the latest mobile application technology, and linking it to the growing popularity of people participating in challenges for a cause.\nI've employed the latest technology stacks for both backend and frontend, optimizing for usability and global scalability.",
      industry: "Charity",
      technologies:
        "Django REST framework, React Native (iOS & Android), PostgresSQL, Redis, AWS Servers/Instances, Google Firebase, Twilio Integration, Super Admin panel, Stripe",
      url: {
        name: "givenget.org",
        link: "https://givenget.org/",
      },
      thumbImage: "images/projects/Python2/Initial.png",
      sliderImages: [
        "images/projects/Python2/Screenshot_1.png",
        "images/projects/Python2/Screenshot_2.png",
        "images/projects/Python2/Screenshot_3.png",
      ],
      categories: [filters.BRAND],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme
                  ? "text-muted-custom opacity-1"
                  : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs  border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image w-100"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          {/* eslint-disable-next-line  */}
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            aria-label={`Open details for ${project.title}`}
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            {/* <span className="text-light">Category</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
